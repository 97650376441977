import React from "react";

const Contact=()=>{
    return (
       
    <div>
  {/* Sidebar Modal */}
  <div className="sidebarModal modal right fade" id="sidebarModal" tabIndex={-1} role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <button type="button" className="close" data-bs-dismiss="modal"><i className="ri-close-line" /></button>
        <div className="modal-body">
          <div className="title">
            <a href="index.html">
              <img src="assets/images/logo.png" className="black-logo" alt="logo" />
              <img src="assets/images/white-logo.png" className="white-logo" alt="logo" />
            </a>
          </div>
          <div className="sidebar-content">
            <h3>About Us</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div className="sidebar-btn">
              <a href="contact.html" className="default-btn">Let’s Talk <i className="ri-message-line" /><span /></a>
            </div>
          </div>
          <div className="sidebar-contact-info">
            <h3>Contact Information</h3>
            <ul className="info-list">
              <li><i className="ri-phone-fill" /> <a href="tel:+11234567890">+1 (123) 456 7890</a></li>
              <li><i className="ri-mail-line" /> <a href="/cdn-cgi/l/email-protection#5d35383131321d2d313239733e3230"><span className="__cf_email__" data-cfemail="79111c151516390915161d571a1614">[email&nbsp;protected]</span></a></li>
              <li><i className="ri-map-pin-line" /> 121 King St, Melbourne VIC <br /> 3000, Australia.</li>
            </ul>
          </div>
          <ul className="sidebar-social-list">
            <li><a href="#" target="_blank"><i className="ri-facebook-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-twitter-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-linkedin-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-instagram-fill" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  {/* End Sidebar Modal */}
  {/* Start Page Banner Area */}
  <div className="page-banner-area">
    <div className="container">
      <div className="page-banner-content">
        <h2>Contact</h2>
        <ul>
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
    <div className="page-banner-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-1.png" alt="image" />
    </div>
    <div className="page-banner-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-2.png" alt="image" />
    </div>
    <div className="page-banner-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-3.png" alt="image" />
    </div>
    <div className="page-banner-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Page Banner Area */}
  {/* Start Contact Area */}
  <div className="contact-area ptb-100">
    <div className="container">
      <div className="contact-form">
        <h3>Get in Touch</h3>
        <form id="contactForm">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="Name*" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Email*" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" className="form-control" placeholder="Phone" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <input type="text" name="msg_subject" id="msg_subject" className="form-control" required data-error="Please enter your subject" placeholder="Subject" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <textarea name="message" className="form-control" id="message" cols={30} rows={5} required data-error="Write your message" placeholder="Message" defaultValue={""} />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <button type="submit" className="default-btn">Send Message <i className="ri-arrow-right-line" /><span /></button>
              <div id="msgSubmit" className="h3 text-center hidden" />
              <div className="clearfix" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* End Contact Area */}
  {/* Start Contact Info Area */}
  <div className="contact-info-area pb-100">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="contact-address">
            <h3>Contact Address:</h3>
            <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
            <ul className="address-info">
              <li>
                <i className="ri-customer-service-line" />
                <a href="tel:3128959800">(312) 895-9800</a>
              </li>
              <li>
                <i className="ri-global-line" />
                <a href="/cdn-cgi/l/email-protection#264e434a4a49084b4366564a49420845494b"><span className="__cf_email__" data-cfemail="7c14191010135211193c0c101318521f1311">[email&nbsp;protected]</span></a>
              </li>
              <li>
                <i className="ri-map-pin-line" /> 121 St, Melbourne VIC 3000, Australia
              </li>
            </ul>
            <ul className="address-social">
              <li>
                <a href="#" target="_blank">
                  <i className="ri-facebook-line" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-twitter-fill" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-linkedin-fill" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-messenger-line" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8385385572983!2d144.95358331584498!3d-37.81725074201705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sbd!4v1612419490850!5m2!1sen!2sbd" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Contact Info Area */}
</div>

    );
}

export default Contact;