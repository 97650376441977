import React from "react";

const Home=()=>{
    return (
        <div>
  {/* Start Main Banner Area */}
  <div className="main-banner-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-12">
          <div className="main-banner-content">
            <div className="tag wow animate__animated animate__fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
              <img src="assets/images/main-banner/banner-one/tag-icon.png" alt="image" /> # Plod Best Startup Company.
            </div>
            <h1 className="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">My Name is Neeraj Singh Bohra</h1>
            <p className="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio at ut tincidunt porttitor molestie aliquet quam cursus. Rhoncus donec libero et volutpat erat posuere sagittis cursus posuere</p>
            <div className="banner-btn">
              <a href="about-1.html" className="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-12">
          <div className="main-banner-animation-image">
            <img src="assets/images/main-banner/banner-one/img1.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="200ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img2.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img3.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="400ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img4.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="900ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img5.png" className="wow fadeInUp" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img6.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="700ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img7.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="800ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img8.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="800ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img9.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img10.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="100ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img11.png" className="wow animate__animated animate__fadeInDown" data-wow-delay="300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img12.png" className="wow animate__animated animate__fadeInLeft" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img13.png" className="wow animate__animated animate__fadeInLeft" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img14.png" className="wow animate__animated animate__fadeInLeft" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/img15.png" className="wow animate__animated animate__fadeInLeft" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" />
            <img src="assets/images/main-banner/banner-one/main-pic.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="main-banner-shape-1">
      <img src="assets/images/main-banner/banner-one/shape-1.png" alt="image" />
    </div>
    <div className="main-banner-shape-2">
      <img src="assets/images/main-banner/banner-one/shape-2.png" alt="image" />
    </div>
    <div className="main-banner-shape-3">
      <img src="assets/images/main-banner/banner-one/shape-3.png" alt="image" />
    </div>
    <div className="main-banner-shape-4">
      <img src="assets/images/main-banner/banner-one/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Main Banner Area */}
  {/* Start Partner Area */}
  <div className="partner-area">
    <div className="container">
      <div className="partner-box">
        <div className="partner-slides owl-carousel owl-theme">
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-1.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-2.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-3.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-4.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-5.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-1.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-2.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-3.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-4.png" alt="image" /></a>
          </div>
          <div className="single-partner">
            <a href="#"><img src="assets/images/partner/partner-5.png" alt="image" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="partner-shape-1" data-speed="0.06" data-revert="true">
      <img src="assets/images/partner/shape-1.png" alt="image" />
    </div>
    <div className="partner-shape-2" data-speed="0.06" data-revert="true">
      <img src="assets/images/partner/shape-2.png" alt="image" />
    </div>
  </div>
  {/* End Partner Area */}
  {/* Start Features Area */}
  <div className="features-area pt-100 pb-70">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-1.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Strategic Planning</a>
            </h3>
            <p>Best Strategic planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-2.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Research &amp; Development</a>
            </h3>
            <p>Plod is totally desigital techonology based creative agency ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-3.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Design &amp; Implimentation</a>
            </h3>
            <p>Best Strategic planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="features-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/features/shape-1.png" alt="image" />
    </div>
    <div className="features-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/features/shape-2.png" alt="image" />
    </div>
    <div className="features-shape-3">
      <img src="assets/images/features/shape-3.png" alt="image" />
    </div>
    <div className="features-shape-4">
      <img src="assets/images/features/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Features Area */}
  {/* Start Experiences Area */}
  <div className="experiences-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="experiences-item">
            <div className="experiences-content">
              <div className="tag">
                <img src="assets/images/experiences/tag-icon.png" alt="image" />
              </div>
              <h3>Over 12 Year Professional Experiences</h3>
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor. nam elit nulla eget sodales dui pulvinar. Best Strategic planning dolor sit sectetur morethe</p>
              <p>Scelerisque amet odio velit eu auctor. Aliquet nam elit.</p>
            </div>
            <div className="experiences-inner-content">
              <img src="assets/images/experiences/img1.png" alt="image" />
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. risque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar. Best eone Strategic planning dolor.</p>
            </div>
            <div className="experiences-inner-content">
              <img src="assets/images/experiences/img2.png" alt="image" />
              <p>Plod digital agency for your start planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar. Best Strategic planning.</p>
            </div>
            <div className="experiences-inner-content">
              <img src="assets/images/experiences/img3.png" alt="image" />
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. risque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar. Best eone Strategic planning dolor.</p>
            </div>
            <div className="experiences-btn">
              <a href="about-1.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="experiences-image" data-speed="0.02" data-revert="true">
            <img src="assets/images/experiences/experiences-1.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="experiences-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/experiences/shape-1.png" alt="image" />
    </div>
    <div className="experiences-shape-2" data-speed="0.06" data-revert="true">
      <img src="assets/images/experiences/shape-2.png" alt="image" />
    </div>
    <div className="experiences-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/experiences/shape-3.png" alt="image" />
    </div>
    <div className="experiences-shape-4" data-speed="0.06" data-revert="true">
      <img src="assets/images/experiences/shape-2.png" alt="image" />
    </div>
    <div className="experiences-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/experiences/shape-3.png" alt="image" />
    </div>
    <div className="experiences-shape-6" data-speed="0.06" data-revert="true">
      <img src="assets/images/experiences/shape-4.png" alt="image" />
    </div>
    <div className="experiences-shape-7" data-speed="0.08" data-revert="true">
      <img src="assets/images/experiences/shape-5.png" alt="image" />
    </div>
  </div>
  {/* End Experiences Area */}
  {/* Start Services Area */}
  <div className="services-area pt-100 pb-70">
    <div className="container">
      <div className="section-title">
        <h2>Services We Offer</h2>
        <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor nam elit nulla eget sodales dui pulvina</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon">
              <i className="ri-quill-pen-line" />
            </div>
            <h3>
              <a href="services-details.html">Brand Identity Design</a>
            </h3>
            <p>Branding just like your personal identity makes you uniquely you your brand identity is the special sauce of your business that sets you apart from every other Tom Dick and Harry Inc.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon bg-36CC72">
              <i className="ri-pie-chart-line" />
            </div>
            <h3>
              <a href="services-details.html">Digital Marketing</a>
            </h3>
            <p>Plod offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon bg-FF414B">
              <i className="ri-lightbulb-line" />
            </div>
            <h3>
              <a href="services-details.html">Design and Development</a>
            </h3>
            <p>Plod offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon bg-FF6D3D">
              <i className="ri-customer-service-2-line" />
            </div>
            <h3>
              <a href="services-details.html">IT Consulting Service</a>
            </h3>
            <p>Plod offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon bg-8932F8">
              <i className="ri-cloud-line" />
            </div>
            <h3>
              <a href="services-details.html">Cloud Computing</a>
            </h3>
            <p>Plod offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-services">
            <div className="icon bg-FFCA40">
              <i className="ri-layout-row-line" />
            </div>
            <h3>
              <a href="services-details.html">Domain and Hosting</a>
            </h3>
            <p>Plod offering you the best digital marketing services to just like your personal identity makes you uniquely you, your brand identity is the special sauce of your to our customer.</p>
            <a href="services-details.html" className="services-btn">Read More <i className="ri-arrow-right-line" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="services-shape-1" data-speed="0.02" data-revert="true">
      <img src="assets/images/services/shape-1.png" alt="image" />
    </div>
    <div className="services-shape-2" data-speed="0.02" data-revert="true">
      <img src="assets/images/services/shape-2.png" alt="image" />
    </div>
    <div className="services-shape-3" data-speed="0.02" data-revert="true">
      <img src="assets/images/services/shape-3.png" alt="image" />
    </div>
    <div className="services-shape-4" data-speed="0.02" data-revert="true">
      <img src="assets/images/services/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Services Area */}
  {/* Start Support Area */}
  <div className="support-area">
    <div className="container">
      <div className="support-content">
        <div className="tag">
          <img src="assets/images/experiences/tag-icon.png" alt="image" />
        </div>
        <h3>Lightning-Fast Tech Support, Guaranteed</h3>
        <p>As a midsize software development company, we combine the best of both worlds. We have the focus and speed of the small IT outsourcing companies along with the scalability and expertise of the big ones.</p>
        <span>Regan Rosen, CEO at Flod Agency</span>
      </div>
    </div>
    <div className="support-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-1.png" alt="image" />
    </div>
    <div className="support-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-2.png" alt="image" />
    </div>
    <div className="support-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-3.png" alt="image" />
    </div>
    <div className="support-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-4.png" alt="image" />
    </div>
    <div className="support-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-5.png" alt="image" />
    </div>
    <div className="support-shape-6" data-speed="0.02" data-revert="true">
      <img src="assets/images/support/shape-6.png" alt="image" />
    </div>
  </div>
  {/* End Support Area */}
  {/* Start Cases Area */}
  <div className="cases-area ptb-100">
    <div className="container">
      <div className="section-title">
        <h2>Our Realizations / Recent Cases</h2>
        <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor nam elit nulla eget sodales dui pulvina</p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="single-cases">
            <div className="cases-image">
              <a href="case-study-details.html">
                <img src="assets/images/cases-study/cases-1.jpg" alt="image" />
              </a>
            </div>
            <div className="cases-content">
              <div className="tag-1">Branding</div>
              <div className="tag-2">Development</div>
              <h3>
                <a href="case-study-details.html">Product Branding and Web Development</a>
              </h3>
              <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom, Dick, and Harry, Inc. Branding just like your personal identity
                makes you uniquely.</p>
            </div>
          </div>
          <div className="single-cases">
            <div className="cases-image">
              <a href="case-study-details.html">
                <img src="assets/images/cases-study/cases-2.jpg" alt="image" />
              </a>
            </div>
            <div className="cases-content">
              <div className="tag-1">Business</div>
              <h3>
                <a href="case-study-details.html">Brand Identity and Mockup</a>
              </h3>
              <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="single-cases">
            <div className="cases-image">
              <a href="case-study-details.html">
                <img src="assets/images/cases-study/cases-3.jpg" alt="image" />
              </a>
            </div>
            <div className="cases-content">
              <div className="tag-1">App Design</div>
              <h3>
                <a href="case-study-details.html">Banking Mobile iOS App Design</a>
              </h3>
              <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets you apart from every other Tom, Dick, and Harry, Inc. Branding just like your personal identity
                makes you uniquely.</p>
            </div>
          </div>
          <div className="single-cases">
            <div className="cases-image">
              <a href="case-study-details.html">
                <img src="assets/images/cases-study/cases-4.jpg" alt="image" />
              </a>
            </div>
            <div className="cases-content">
              <div className="tag-1">App Design</div>
              <h3>
                <a href="case-study-details.html">Plod Website Design and Development</a>
              </h3>
              <p>Branding just like your personal identity makes you uniquely you, your brand identity is the special sauce of your business that sets.</p>
            </div>
          </div>
        </div>
        <div className="cases-view-all-btn">
          <a href="case-study-1.html" className="default-btn">View All Projects <i className="ri-briefcase-line" /><span /></a>
        </div>
      </div>
    </div>
    <div className="cases-shape-1" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-1.png" alt="image" />
    </div>
    <div className="cases-shape-2" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-2.png" alt="image" />
    </div>
    <div className="cases-shape-3" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-3.png" alt="image" />
    </div>
    <div className="cases-shape-4" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-4.png" alt="image" />
    </div>
    <div className="cases-shape-5" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-5.png" alt="image" />
    </div>
    <div className="cases-shape-6" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-4.png" alt="image" />
    </div>
    <div className="cases-shape-7" data-speed="0.04" data-revert="true">
      <img src="assets/images/cases-study/shape-5.png" alt="image" />
    </div>
  </div>
  {/* End Cases Area */}
  {/* Start Clients Area */}
  <div className="clients-area ptb-100">
    <div className="container">
      <div className="section-title">
        <h2>Our Respected Clients</h2>
        <p>Which peoples loves us a lot, please check out what about says about us</p>
      </div>
      <div className="clients-slides owl-carousel owl-theme">
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="clients-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-1.png" alt="image" />
    </div>
    <div className="clients-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-2.png" alt="image" />
    </div>
    <div className="clients-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-3.png" alt="image" />
    </div>
  </div>
  {/* End Clients Area */}
  {/* Start Plans Area */}
  <div className="plans-area pt-100 pb-100">
    <div className="container">
      <div className="section-title">
        <h2>Choose Your Best Plans</h2>
        <p>Pay by monthly or yearly, you can cancel it anytime whatever you want</p>
      </div>
      <div className="plans-switcher">
        <div className="switcher-box">
          <label className="toggler toggler--is-active" id="filt-monthly">Bill Monthly</label>
          <div className="toggle">
            <input type="checkbox" id="switcher" className="check" />
            <b className="b switch" />
          </div>
          <label className="toggler" id="filt-yearly">Bill Yearly</label>
        </div>
      </div>
      <div id="monthly" className="wrapper-full">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table">
              <div className="plans-header">
                <h3>Individual</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$49 <span>/Month</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 10 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 10 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table active-box">
              <div className="plans-header">
                <h3>Small Team</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$69 <span>/Month</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 15 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 15 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
              <div className="popular-tag">
                <span>Most Popular</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table">
              <div className="plans-header">
                <h3>Business</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$79 <span>/Month</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 20 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 20 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="yearly" className="wrapper-full hide">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table">
              <div className="plans-header">
                <h3>Individual</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$249 <span>/Yearly</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 10 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 10 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table active-box">
              <div className="plans-header">
                <h3>Small Team</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$269 <span>/Yearly</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 15 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 15 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
              <div className="popular-tag">
                <span>Most Popular</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-plans-table">
              <div className="plans-header">
                <h3>Business</h3>
                <p>Powerful &amp; Awesome Elements</p>
              </div>
              <div className="price">$279 <span>/Yearly</span></div>
              <div className="plans-btn">
                <a href="#" className="default-btn">Purchase Now <i className="ri-arrow-right-line" /><span /></a>
              </div>
              <ul className="plans-features-list">
                <li><i className="ri-check-line" /> Up to 20 Website</li>
                <li><i className="ri-check-line" /> Lifetime Free Support</li>
                <li><i className="ri-check-line" /> 20 GB Dedicated Hosting Free</li>
                <li><i className="ri-check-line" /> 24/7 Hours Support</li>
                <li><i className="ri-check-line" /> SEO Optimized</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="view-all-plans-btn">
        <a href="pricing.html" className="plans-btn-one">See All Pricing Plan</a>
      </div>
    </div>
    <div className="plans-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/plans/shape-1.png" alt="image" />
    </div>
    <div className="plans-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/plans/shape-2.png" alt="image" />
    </div>
    <div className="plans-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/plans/shape-3.png" alt="image" />
    </div>
  </div>
  {/* End Plans Area */}
  {/* Start Blog Area */}
  <div className="blog-area pt-100 pb-70">
    <div className="container">
      <div className="section-title">
        <h2>Popular Blog Post</h2>
        <p>We are try to Update with Latest Article and Blog Post Best Strategic Planning</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="single-blog">
            <div className="blog-image">
              <a href="blog-details.html"><img src="assets/images/blog/blog-1.jpg" alt="image" /></a>
            </div>
            <div className="blog-content">
              <ul className="entry-meta">
                <li className="tag">Branding</li>
                <li>
                  <i className="ri-time-line" /> March 14, 2024
                </li>
                <li>
                  <i className="ri-message-2-line" /> (0) Comment
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">Branding Involves Developing a Strategy to Creating a Point of Differentiation.</a>
              </h3>
              <a href="blog-details.html" className="blog-btn">Read More <i className="ri-arrow-right-line" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-blog">
            <div className="blog-image">
              <a href="blog-details.html"><img src="assets/images/blog/blog-2.jpg" alt="image" /></a>
            </div>
            <div className="blog-content">
              <ul className="entry-meta">
                <li className="tag">Agency</li>
                <li>
                  <i className="ri-time-line" /> March 14, 2024
                </li>
                <li>
                  <i className="ri-message-2-line" /> (0) Comment
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">Design is a Plan or Specification For The Construction of an Object.</a>
              </h3>
              <a href="blog-details.html" className="blog-btn">Read More <i className="ri-arrow-right-line" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-blog">
            <div className="blog-image">
              <a href="blog-details.html"><img src="assets/images/blog/blog-3.jpg" alt="image" /></a>
            </div>
            <div className="blog-content">
              <ul className="entry-meta">
                <li className="tag">Marketing</li>
                <li>
                  <i className="ri-time-line" /> March 14, 2024
                </li>
                <li>
                  <i className="ri-message-2-line" /> (0) Comment
                </li>
              </ul>
              <h3>
                <a href="blog-details.html">Branding Involves Developing the Strategy to Create a Point.</a>
              </h3>
              <a href="blog-details.html" className="blog-btn">Read More <i className="ri-arrow-right-line" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="blog-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/blog/shape-1.png" alt="image" />
    </div>
    <div className="blog-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/blog/shape-2.png" alt="image" />
    </div>
  </div>
  {/* End Blog Area */}
  {/* Start Overview Area */}
  <div className="overview-area pb-100">
    <div className="container">
      <div className="overview-box">
        <div className="overview-content">
          <h3>Let's Make Something Amazing Together</h3>
          <div className="overview-btn">
            <a href="contact.html" className="overview-btn-one">Get Started</a>
            <img src="assets/images/overview/bar.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="overview-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-1.png" alt="image" />
    </div>
    <div className="overview-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-2.png" alt="image" />
    </div>
    <div className="overview-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-3.png" alt="image" />
    </div>
    <div className="overview-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-4.png" alt="image" />
    </div>
    <div className="overview-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-5.png" alt="image" />
    </div>
  </div>
  {/* End Overview Area */}
</div>

    );
}

export default Home;