import React from "react";

const Footer=()=>{
    return (
        <div>
  {/* Start Footer Area */}
  <footer className="footer-area pt-100">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-3 col-sm-6">
          <div className="single-footer-widget">
            <div className="widget-logo">
              <a href="index.html">
                <img src="assets/images/logo.png" className="black-logo" alt="logo" />
                <img src="assets/images/white-logo.png" className="white-logo" alt="logo" />
              </a>
            </div>
            <p>Best solution for your it startup business consecteturadipiscing elit. Scelerisque amet odio velit auctor. nam elit nulla.</p>
            <ul className="widget-info">
              <li>
                <i className="ri-customer-service-line" />
                <a href="tel:3128959800">(312) 895-9800</a>
              </li>
              <li>
                <i className="ri-global-line" />
                <a href="/cdn-cgi/l/email-protection#f199949d9d9edf9c94b1819d9e95df929e9c"><span className="__cf_email__" data-cfemail="59313c35353677343c192935363d773a3634">[email&nbsp;protected]</span></a>
              </li>
              <li>
                <i className="ri-map-pin-line" /> 121 St, Melbourne VIC 3000, Australia
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-sm-3">
          <div className="single-footer-widget">
            <h3>Quick Link</h3>
            <ul className="footer-links-list">
              <li><a href="gallery.html">Gallery</a></li>
              <li><a href="about-1.html">About Us</a></li>
              <li><a href="contact.html">Contact</a></li>
              <li><a href="purchase-guide.html">Purchase Guide</a></li>
              <li><a href="terms-of-service.html">Terms of Service</a></li>
              <li><a href="faq.html">Faq’s</a></li>
              <li><a href="privacy-policy.html">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-sm-3">
          <div className="single-footer-widget">
            <h3>About Us</h3>
            <ul className="footer-links-list">
              <li><a href="gallery.html">Gallery</a></li>
              <li><a href="about-1.html">About Us</a></li>
              <li><a href="contact.html">Contact</a></li>
              <li><a href="purchase-guide.html">Purchase Guide</a></li>
              <li><a href="terms-of-service.html">Terms of Service</a></li>
              <li><a href="faq.html">Faq’s</a></li>
              <li><a href="privacy-policy.html">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="single-footer-widget">
            <h3>Services</h3>
            <ul className="footer-links-list">
              <li><a href="pricing.html">Pricing</a></li>
              <li><a href="services-details.html">Digital Marketing</a></li>
              <li><a href="purchase-guide.html">Purchase Guide</a></li>
              <li><a href="contact.html">Support</a></li>
              <li><a href="testimonials.html">Testimonials</a></li>
              <li><a href="services-details.html">Cloud Computing</a></li>
              <li><a href="privacy-policy.html">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="single-footer-widget">
            <h3>Newsletter</h3>
            <div className="widget-newsletter">
              <div className="newsletter-content">
                <p>Best solution for your it startup business consectetur adipiscing elit.</p>
              </div>
              <form className="newsletter-form" data-bs-toggle="validator">
                <input type="email" className="input-newsletter" placeholder="Enter email" name="EMAIL" required autoComplete="off" />
                <button type="submit"><i className="ri-send-plane-2-line" /></button>
                <div id="validator-newsletter" className="form-result" />
              </form>
            </div>
            <ul className="widget-social">
              <li>
                <a href="#" target="_blank">
                  <i className="ri-facebook-line" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-twitter-fill" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-linkedin-fill" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="ri-messenger-line" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <div className="container">
        <div className="copyright-area-content">
          <p>
            Copyright ©
            Plod. All Rights Reserved by
            <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
          </p>
        </div>
      </div>
    </div>
    <div className="footer-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/footer/shape-1.png" alt="image" />
    </div>
    <div className="footer-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/footer/shape-2.png" alt="image" />
    </div>
    <div className="footer-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/footer/shape-3.png" alt="image" />
    </div>
    <div className="footer-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/footer/shape-4.png" alt="image" />
    </div>
    <div className="footer-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/footer/shape-5.png" alt="image" />
    </div>
  </footer>
  {/* End Footer Area */}
  {/* Start Go Top Area */}
  <div className="go-top">
    <i className="ri-arrow-up-s-line" />
  </div>
  {/* End Go Top Area */}
</div>



    );
}
export default Footer;