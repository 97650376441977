import React from "react";

const About=(props)=>{
        return (
            <div>
  {/* Start Page Banner Area */}
  <div className="page-banner-area">
    <div className="container">
      <div className="page-banner-content">
        <h2>About Us Pankaj Kumar</h2>
        <ul>
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>About Us</li>
        </ul>
      </div>
    </div>
    <div className="page-banner-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-1.png" alt="image" />
    </div>
    <div className="page-banner-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-2.png" alt="image" />
    </div>
    <div className="page-banner-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-3.png" alt="image" />
    </div>
    <div className="page-banner-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/page-banner/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Page Banner Area */}
  {/* Start Features Area */}
  <div className="features-area pt-100 pb-70">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-1.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Strategic Planning</a>
            </h3>
            <p>Best Strategic planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-2.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Research &amp; Development</a>
            </h3>
            <p>Plod is totally desigital techonology based creative agency ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="single-features">
            <a href="services-details.html"><img src="assets/images/features/features-3.png" alt="image" /></a>
            <h3>
              <a href="services-details.html">Design &amp; Implimentation</a>
            </h3>
            <p>Best Strategic planning dolor sit amet, consectetur adipiscing elit. Scelerisque amet odio velit, eu, auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
            <div className="features-btn">
              <a href="services-details.html" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="features-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/features/shape-1.png" alt="image" />
    </div>
    <div className="features-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/features/shape-2.png" alt="image" />
    </div>
  </div>
  {/* End Features Area */}
  {/* Start About Area */}
  <div className="about-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="about-image" data-speed="0.02" data-revert="true">
            <img src="assets/images/about/about.png" alt="image" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-item">
            <div className="about-content">
              <div className="tag">
                <img src="assets/images/about/tag-icon.png" alt="image" />
              </div>
              <h3>Over 12 Year Professional Experiences</h3>
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit auctor. nam elit nulla eget sodales dui pulvinar. Best Strategic planning dolor sit sectetur morethe</p>
              <p>Scelerisque amet odio velit eu auctor. Aliquet nam elit.</p>
            </div>
            <div className="about-inner-content">
              <img src="assets/images/about/img1.png" alt="image" />
              <h4>Strategic Planning</h4>
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. risque amet odio velit eu auctor. Best eone Strategic planning dolor.</p>
            </div>
            <div className="about-inner-content">
              <img src="assets/images/about/img2.png" alt="image" />
              <h4>Achieve the Goal</h4>
              <p>Plod digital agency for your start planning dolor sit amet consectetur adipiscing elit. Scelerisque amet odio velit eu auctor. Aliquet nam elit nulla eget sodales dui pulvinar.</p>
            </div>
            <div className="about-inner-content">
              <img src="assets/images/about/img3.png" alt="image" />
              <h4>Startup Applications</h4>
              <p>Best Strategic planning dolor sit amet consectetur adipiscing elit. risque amet odio velit eu auctor</p>
            </div>
            <div className="about-btn">
              <a href="#" className="default-btn">Read More <i className="ri-arrow-right-line" /><span /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="about-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-1.png" alt="image" />
    </div>
    <div className="about-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-2.png" alt="image" />
    </div>
    <div className="about-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-3.png" alt="image" />
    </div>
    <div className="about-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-4.png" alt="image" />
    </div>
    <div className="about-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-5.png" alt="image" />
    </div>
    <div className="about-shape-6" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-4.png" alt="image" />
    </div>
    <div className="about-shape-7" data-speed="0.08" data-revert="true">
      <img src="assets/images/about/shape-5.png" alt="image" />
    </div>
  </div>
  {/* End About Area */}
  {/* Start Fun Facts Area */}
  <div className="fun-facts-area ptb-100">
    <div className="container">
      <div className="fun-facts-box">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <div className="icon">
                <i className="ri-user-line" />
              </div>
              <h3>
                <span className="odometer" data-count={3230}>00</span>
                <span className="sign-icon">+</span>
              </h3>
              <p>Total Cases</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <div className="icon">
                <i className="ri-briefcase-line" />
              </div>
              <h3>
                <span className="odometer" data-count={3026}>00</span>
                <span className="sign-icon">+</span>
              </h3>
              <p>Case Solved</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <div className="icon">
                <i className="ri-trophy-line" />
              </div>
              <h3>
                <span className="odometer" data-count={320}>00</span>
                <span className="sign-icon">+</span>
              </h3>
              <p>Winning Awards</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <div className="icon">
                <i className="ri-flag-line" />
              </div>
              <h3>
                <span className="odometer" data-count={102}>00</span>
                <span className="sign-icon">+</span>
              </h3>
              <p>Country Over</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="fun-facts-shape-1" data-speed="0.09" data-revert="true">
      <img src="assets/images/fun-facts/shape-1.png" alt="image" />
    </div>
    <div className="fun-facts-shape-2" data-speed="0.05" data-revert="true">
      <img src="assets/images/fun-facts/shape-2.png" alt="image" />
    </div>
    <div className="fun-facts-shape-3" data-speed="0.09" data-revert="true">
      <img src="assets/images/fun-facts/shape-3.png" alt="image" />
    </div>
    <div className="fun-facts-shape-4" data-speed="0.05" data-revert="true">
      <img src="assets/images/fun-facts/shape-4.png" alt="image" />
    </div>
  </div>
  {/* End Fun Facts Area */}
  {/* Start Support Area */}
  <div className="support-area">
    <div className="container">
      <div className="support-content">
        <div className="tag">
          <img src="assets/images/experiences/tag-icon.png" alt="image" />
        </div>
        <h3>Lightning-Fast Tech Support, Guaranteed</h3>
        <p>As a midsize software development company, we combine the best of both worlds. We have the focus and speed of the small IT outsourcing companies along with the scalability and expertise of the big ones.</p>
        <span>Regan Rosen, CEO at Flod Agency</span>
      </div>
    </div>
    <div className="support-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-1.png" alt="image" />
    </div>
    <div className="support-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-2.png" alt="image" />
    </div>
    <div className="support-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-3.png" alt="image" />
    </div>
    <div className="support-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-4.png" alt="image" />
    </div>
    <div className="support-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/support/shape-5.png" alt="image" />
    </div>
    <div className="support-shape-6" data-speed="0.02" data-revert="true">
      <img src="assets/images/support/shape-6.png" alt="image" />
    </div>
  </div>
  {/* End Support Area */}
  {/* Start Team Area */}
  <div className="team-area ptb-100">
    <div className="container">
      <div className="section-title">
        <h2>Meet With Our Consultants</h2>
        <p>Which peoples loves us a lot, please check out what about says about us</p>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="single-team-box">
            <div className="image">
              <img src="assets/images/team/team-1.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-facebook-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-twitter-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-linkedin-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-messenger-fill" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Oliver Watson</h3>
              <span>Senior IT Consultant</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-team-box">
            <div className="image">
              <img src="assets/images/team/team-2.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-facebook-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-twitter-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-linkedin-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-messenger-fill" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Leah Chatman</h3>
              <span>Financial Consultant</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-team-box">
            <div className="image">
              <img src="assets/images/team/team-3.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-facebook-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-twitter-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-linkedin-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-messenger-fill" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Louis Agassiz</h3>
              <span>Web Developer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-team-box">
            <div className="image">
              <img src="assets/images/team/team-4.jpg" alt="image" />
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-facebook-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-twitter-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-linkedin-fill" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="ri-messenger-fill" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Carl Anderson</h3>
              <span>Web Designer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="see-team-member-btn">
      <a href="#" className="see-btn">See Team Member</a>
    </div>
  </div>
  {/* End Team Area */}
  {/* Start Clients Area */}
  <div className="clients-area ptb-100">
    <div className="container">
      <div className="section-title">
        <h2>Our Respected Clients</h2>
        <p>Which peoples loves us a lot, please check out what about says about us</p>
      </div>
      <div className="clients-slides owl-carousel owl-theme">
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-1.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>Deanna Hodges</h3>
            <span>Spotify Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-2.png" alt="image" />
          <p>‘’Plod is the best digital agency in our area As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies.</p>
          <div className="title-name">
            <h3>Londynn Vargas</h3>
            <span>Web Designer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
        <div className="clients-item">
          <img src="assets/images/clients/clients-3.png" alt="image" />
          <p>‘’As a midsize software developent company we combine the best of both worlds. We have the focus and speed of the small it outsurcing companies along with the scalability and expertise of the big ones.’’</p>
          <div className="title-name">
            <h3>James Anderson</h3>
            <span>Web Developer</span>
          </div>
          <div className="star-rating">
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
            <i className="ri-star-fill" />
          </div>
          <div className="shape-1">
            <img src="assets/images/clients/shape-4.png" alt="image" />
          </div>
          <div className="shape-2">
            <img src="assets/images/clients/shape-5.png" alt="image" />
          </div>
          <div className="shape-3">
            <img src="assets/images/clients/shape-6.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="clients-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-1.png" alt="image" />
    </div>
    <div className="clients-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-2.png" alt="image" />
    </div>
    <div className="clients-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/clients/shape-3.png" alt="image" />
    </div>
  </div>
  {/* End Clients Area */}
  {/* Start FAQ Area */}
  <div className="faq-area ptb-100">
    <div className="container">
      <div className="section-title">
        <h2>Frequently Asked Questions</h2>
        <p>Which peoples loves us a lot, please check out what about says about us</p>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-4 col-md-12">
          <div className="faq-image">
            <img src="assets/images/faq/faq-1.png" alt="image" />
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="faq-accordion">
            <div className="accordion">
              <div className="accordion-item">
                <div className="accordion-title active">
                  <i className="ri-add-line" /> Why Are Consultants Important?
                </div>
                <div className="accordion-content show">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-title">
                  <i className="ri-add-line" /> What is The Purpose of a Consultant?
                </div>
                <div className="accordion-content">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-title">
                  <i className="ri-add-line" /> What Attracts You To The Role of a Consultant?
                </div>
                <div className="accordion-content">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-title">
                  <i className="ri-add-line" /> What Are The Advantages of Being a Consultant?
                </div>
                <div className="accordion-content">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-title">
                  <i className="ri-add-line" /> Is Consulting a Good Career?
                </div>
                <div className="accordion-content">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-title">
                  <i className="ri-add-line" /> How is Working in Consulting?
                </div>
                <div className="accordion-content">
                  <p>The IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more. IT consulting services are important because they play a vital role in businesses by covering
                    the management, implementation, deployment, and maintenance of an IT infrastructure. IT industry offers a sea of options, from platforms, programming languages, methodologies, technologies, tools, and more.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End FAQ Area */}
  {/* Start Overview Area */}
  <div className="overview-area pb-100">
    <div className="container">
      <div className="overview-box">
        <div className="overview-content">
          <h3>Let's Make Something Amazing Together</h3>
          <div className="overview-btn">
            <a href="contact.html" className="overview-btn-one">Get Started</a>
            <img src="assets/images/overview/bar.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div className="overview-shape-1" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-1.png" alt="image" />
    </div>
    <div className="overview-shape-2" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-2.png" alt="image" />
    </div>
    <div className="overview-shape-3" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-3.png" alt="image" />
    </div>
    <div className="overview-shape-4" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-4.png" alt="image" />
    </div>
    <div className="overview-shape-5" data-speed="0.08" data-revert="true">
      <img src="assets/images/overview/shape-5.png" alt="image" />
    </div>
  </div>
  {/* End Overview Area */}
</div>

        );
}

export default About;