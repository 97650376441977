import React from 'react';
import { Link,useLocation  } from 'react-router-dom';


const Header=()=>{
    const variable = useLocation();
        return (
<div>
  {/* Start Preloader Area */}
  <div className="preloader-area">
    <div className="spinner">
      <div className="inner">
        <div className="disc" />
        <div className="disc" />
        <div className="disc" />
      </div>
    </div>
  </div>
  {/* End Preloader Area */}
  {/* Start Navbar Area */}
  <div className="navbar-area">
    <div className="main-responsive-nav">
      <div className="container">
        <div className="main-responsive-menu">
          <div className="logo">
            <Link to="/"> <h3 className="fw-bold">My Website </h3> </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="main-navbar">
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light">
          <Link className="navbar-brand" to="/"> <h3 className="fw-bold">My Website</h3> </Link>
          <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                
                <Link to="/" className={variable.pathname==='/'?'nav-link active':'nav-link'}>Home </Link>
              </li>
              <li className="nav-item">
                <Link to="/about-us" className={variable.pathname=='/about-us'?'nav-link active':'nav-link'}>About</Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Services 
                  <i className="ri-add-line" />
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="services-1.html" className="nav-link">Services One</a>
                  </li>
                  <li className="nav-item">
                    <a href="services-2.html" className="nav-link">Services Two</a>
                  </li>
                  <li className="nav-item">
                    <a href="services-3.html" className="nav-link">Services Three</a>
                  </li>
                  <li className="nav-item">
                    <a href="services-details.html" className="nav-link">Services Details</a>
                  </li>
                </ul>
              </li>
              
              
              <li className="nav-item">
                <a href="#" className="nav-link">
                  Blog 
                </a>
              </li>
              <li className="nav-item">
                <Link to="contact-us" className={variable.pathname=='/contact-us'?'nav-link active':'nav-link'}>Contact</Link>
              </li>
            </ul>
            <div className="others-options d-flex align-items-center">
             
              <div className="option-item">
                <a href="contact.html" className="default-btn">Request A Quote <i className="ri-message-line" /><span /></a>
              </div>
              <div className="option-item">
                <div className="side-menu-btn">
                  <i className="ri-menu-4-line" data-bs-toggle="modal" data-bs-target="#sidebarModal" />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div className="others-option-for-responsive">
      <div className="container">
        <div className="dot-menu">
          <div className="inner">
            <div className="circle circle-one" />
            <div className="circle circle-two" />
            <div className="circle circle-three" />
          </div>
        </div>
        <div className="container">
          <div className="option-inner">
            <div className="others-options d-flex align-items-center">
              <div className="option-item">
                <i className="search-btn ri-search-line" />
                <i className="close-btn ri-close-line" />
                <div className="search-overlay search-popup">
                  <div className="search-box">
                    <form className="search-form">
                      <input className="search-input" name="search" placeholder="Search..." type="text" />
                      <button className="search-button" type="submit">
                        <i className="ri-search-line" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="option-item">
                <a href="contact.html" className="default-btn">Let’s Talk <i className="ri-message-line" /><span /></a>
              </div>
              <div className="option-item">
                <div className="side-menu-btn">
                  <i className="ri-menu-4-line" data-bs-toggle="modal" data-bs-target="#sidebarModal" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Navbar Area */}
  {/* Sidebar Modal */}
  <div className="sidebarModal modal right fade" id="sidebarModal" tabIndex={-1} role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <button type="button" className="close" data-bs-dismiss="modal"><i className="ri-close-line" /></button>
        <div className="modal-body">
          <div className="title">
            <a href="index.html">
              <img src="assets/images/logo.png" className="black-logo" alt="logo" />
              <img src="assets/images/white-logo.png" className="white-logo" alt="logo" />
            </a>
          </div>
          <div className="sidebar-content">
            <h3>About Us</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div className="sidebar-btn">
              <a href="contact.html" className="default-btn">Let’s Talk <i className="ri-message-line" /><span /></a>
            </div>
          </div>
          <div className="sidebar-contact-info">
            <h3>Contact Information</h3>
            <ul className="info-list">
              <li><i className="ri-phone-fill" /> <a href="tel:+11234567890">+1 (123) 456 7890</a></li>
              <li><i className="ri-mail-line" /> <a href="/cdn-cgi/l/email-protection#325a575e5e5d72425e5d561c515d5f"><span className="__cf_email__" data-cfemail="59313c353536192935363d773a3634">[email&nbsp;protected]</span></a></li>
              <li><i className="ri-map-pin-line" /> 121 King St, Melbourne VIC <br /> 3000, Australia.</li>
            </ul>
          </div>
          <ul className="sidebar-social-list">
            <li><a href="#" target="_blank"><i className="ri-facebook-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-twitter-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-linkedin-fill" /></a></li>
            <li><a href="#" target="_blank"><i className="ri-instagram-fill" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  {/* End Sidebar Modal */}
</div>
);

}

export default Header;