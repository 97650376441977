import Home from './Components/Home';
import Footer from './Template/Footer';
import Header from './Template/Header';
import About from './Components/About';
import Contact from './Components/Contact';
import { BrowserRouter,Route,Routes  } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header title="" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
        </Routes>
          
        <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
